@font-face {
  font-family: "Platform";
  font-style: normal;
  font-weight: 700;
  src: url("https://greg-thinks-web.s3-ap-southeast-2.amazonaws.com/fonts/Platform-Bold.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  overflow: auto;
}
a {
  text-decoration: none;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root-container {
  width: 100vw;
  min-width: 300px;
  height: 100vh;
  display: flex;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.logo {
  width: 147px;
}

.primary-btn {
  width: 100%;
  outline: 0;
  border: none;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  font-family: "Inter";
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  background: linear-gradient(
    to right bottom,
    rgb(88, 185, 157) 0%,
    rgb(48, 112, 122) 100%
  );
}

.secondary-btn {
  width: 100%;
  outline: 0;
  cursor: pointer;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50px;
  font-family: "Inter";
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  background: transparent;
}

.mt-16p {
  margin-top: 16px;
}

.app-store-cta:nth-child(1) {
  margin-right: 16px;
}
.app-store-cta img {
  height: 40px;
}

.content-block {
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#inner-container {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-height: 80%;
}

#greg-thinks-label {
  width: 33.3333%;
}
#content-container {
  width: 33.3333%;
  min-width: 300px;
}

#content-spacer {
  width: 33.3333%;
  min-width: 300px;
}

.footer {
  flex-direction: row;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.footer #footer-spacer {
  width: 33.3333%;
}

.footer .footer-emblem {
  width: 33.3333%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.description-content {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  margin-top: 60px;
  color: rgb(233, 236, 239);
  font-size: 16px;
  max-width: 400px;
}

.playIcon {
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
}

.horizontal-slider {
  width: 100%;
  height: 8px;
  border-radius: 4px;
}

.audio-track-0 {
  height: 8px;
  border-radius: 4px;
  background: #fff;
}

.audio-track-1 {
  height: 8px;
  border-radius: 4px;
  background: rgba(108, 117, 125, 0.6);
}

.audio-track-thumb {
  width: 20px;
  height: 20px;
  outline: none;
  border-radius: 50%;
  margin-top: -6px;
  background: #fff;
}

.audio-track-thumb-0 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  margin-top: -6px;
  background: #fff;
}

.audio-track-thumb.active {
  width: 20px;
  height: 20px;
  outline: none;
  border-radius: 50%;
  margin-top: -6px;
  background: #fff;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .root-container {
    width: 100vw;
    min-width: 300px;
    height: 100vh;
    display: flex;
    background-position: center;
    background-size: cover;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }

  #greg-thinks-label p {
    margin-bottom: 50px;
  }

  #content-container p {
    margin-top: 0;
  }

  .content-block {
    flex-direction: column;
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
  }

  #inner-container {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-height: 90%;
  }

  #greg-thinks-label {
    width: 33.3333%;
    min-width: 100%;
  }
  #content-container {
    width: 33.3333%;
    min-width: 100%;
  }

  #content-spacer {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .root-container {
    width: 100vw;
    min-width: 300px;
    height: 100vh;
    display: flex;
    background-position: center;
    background-size: cover;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .logo {
    width: 126px;
  }

  .horizontal-slider {
    width: 100%;
    height: 4px;
    border-radius: 4px;
  }

  .audio-track-0 {
    height: 4px;
    border-radius: 4px;
    background: #fff;
  }

  .audio-track-1 {
    height: 4px;
    border-radius: 4px;
    background: rgba(108, 117, 125, 0.6);
  }

  .audio-track-thumb {
    width: 16px;
    height: 16px;
    outline: none;
    border-radius: 50%;
    margin-top: -6px;
    background: #fff;
  }

  .audio-track-thumb-0 {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    outline: none;
    margin-top: -6px;
    background: #fff;
  }

  .audio-track-thumb.active {
    width: 16px;
    height: 16px;
    outline: none;
    border-radius: 50%;
    margin-top: -6px;
    background: #fff;
  }

  #module-app-store {
    display: none;
  }

  .playIcon {
    width: 48px;
    height: 48px;
    margin-bottom: 30px;
  }

  .primary-btn {
    max-width: 120px;
  }

  .content-block {
    flex-direction: column;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .secondary-btn {
    display: none;
  }

  .footer {
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer #footer-spacer {
    display: none;
  }

  .footer .footer-emblem {
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  #greg-thinks-label {
    width: 100%;
    min-width: 300px;
  }

  #greg-thinks-label p {
    margin-bottom: 114px;
  }

  #content-container p {
    margin-top: 0;
    margin-bottom: 59px;
  }
  #content-container {
    width: 100%;
    min-width: 300px;
    margin-bottom: 46px;
  }

  #content-spacer {
    display: none;
  }

  #inner-container {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    width: auto;
    max-height: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}
